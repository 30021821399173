import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';
import 'antd/dist/antd.css';
import { Progress } from 'antd';

import FavoriteButton from '../../../../../../components/FavoriteButton';

import thumbnailClass from '../../../../../../assets/serie/thumbnail-class.png';
import time from '../../../../../../assets/serie/time.png';
import favorite from '../../../../../../assets/serie/favorite.png';
import calendar from '../../../../../../assets/serie/calendar.png';

import {
  Container,
  Thumbnail,
  Informations,
  ClassAttendedIcon,
  TitleContainer,
  Description,
  Options,
  FavoriteIconMobile,
} from './styles';

const Class = ({ lesson, pathname, setShowLoadModal }) => {
  const history = useHistory();
  const [assistedClass, setAssistedClass] = useState(false);

  function renderAssistedClassIcon() {
    if (assistedClass) {
      return (
        <ClassAttendedIcon
          assistedClass={assistedClass}
          onClick={() => setAssistedClass(!assistedClass)}
        >
          <HiCheck color="#fff" size={30} />
        </ClassAttendedIcon>
      );
    }

    return (
      <ClassAttendedIcon
        assistedClass={assistedClass}
        onClick={() => setAssistedClass(!assistedClass)}
      />
    );
  }

  function renderDuration() {
    const { duration } = lesson.post.lesson;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>{duration} min</span>
          </div>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>
              {hours} {hours === 1 ? 'hora' : 'horas'}
              {minutes > 0 && ` e ${minutes} minutos`}
            </span>
          </div>
        );
      }
    }
  }

  const handleCard = () => {
    if (pathname.includes('podcast')) {
      history.push({
        pathname: `/podcast/aula/${lesson.post.lesson.id}`,
      });
    } else if (pathname.includes('meditacao')) {
      history.push({
        pathname: `/meditacao/aula/${lesson.post.lesson.id}`,
      });
    } else {
      history.push({
        pathname: `/aula/${lesson.post.lesson.id}`,
      });
    }
  };

  function renderCard() {
    if (lesson !== undefined) {
      return (
        <Container>
          <div>
            <Thumbnail
              onClick={() => handleCard()}
              src={lesson.post.lesson.thumbnail_url}
              alt="Thumbnail da aula"
            />
            <div>
              {/* {renderAssistedClassIcon()} */}
              <Informations>
                <TitleContainer onClick={() => handleCard()}>
                  <div>{lesson.post.lesson.name}</div>
                  <FavoriteIconMobile
                    src={favorite}
                    alt="Ícone de favoritar aula"
                  />
                </TitleContainer>
                <Description>
                  {renderDuration()}
                  {/* <Progress percent={20} strokeColor="#61E171" /> */}
                </Description>
              </Informations>
            </div>
          </div>
          <Options>
            {/* {renderAssistedClassIcon()} */}
            <FavoriteButton
              post_id={lesson.post.lesson.post_id}
              is_favorite={true}
              favorite_id={lesson.id}
              setShowLoadModal={setShowLoadModal}
            />
            {/* <img src={calendar} alt="Ícone de agendar aula" /> */}
          </Options>
        </Container>
      );
    }
  }

  return <>{renderCard()}</>;
};

export default Class;
